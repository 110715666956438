<template>
  <v-container class="fill-height text-left pa-0" fluid>
    <v-row>
      <v-col>
        <v-card class="fill-height container--fluid" flat tile width="100vw">
          <v-container class="fill-height text-left pa-0" fluid>
            <v-row justify="start">
              <v-col cols="12">
                <v-card class="d-flex fill-height d-flex fluid pa-0 ma-0" flat tile width="100vw">
                  <v-scroll-x-transition appear>
                    <v-img height="200" max-height="200" position="center" src="@/assets/images/bg-breadcrumbs.jpg"
                      transition="slide-x-transition" width="100%">
                      <v-container class="fill-height align-end" fluid>
                        <v-row align="end" justify="start">
                          <v-col cols="12">
                            <div class="white--text">
                              <span class="white--text pl-5 text-h4 text-md-h3 font-weight-black">
                                About us
                              </span>
                            </div>
                          </v-col>
                          <v-col cols="12">
                            <v-breadcrumbs :items="items" dark>
                              <template v-slot:divider>
                                <v-icon>mdi-arrow-right</v-icon>
                              </template>
                              <template v-slot:item="{ item }">
                                <v-breadcrumbs-item :disabled="item.disabled" :href="item.href">
                                  <span class="white--text">
                                    {{ item.text }}
                                  </span>
                                </v-breadcrumbs-item>
                              </template>
                            </v-breadcrumbs>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-img>
                  </v-scroll-x-transition>
                </v-card>
              </v-col>
              <v-col class="align-center justify-start" cols="12">
                <v-card class="ma-1" flat tile>
                  <v-container class="fill-height" fluid>
                    <v-row justify="start">
                      <v-col cols="12" md="6">
                        <v-img height="auto" max-height="500" width="100%" position="center" contain
                          src="@/assets/images/logo-baja-black.png" transition="slide-x-transition">
                        </v-img>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-container fluid class="text-left">
                          <v-row justify="start">
                            <v-col cols="12">
                              <span class="text-h5 text-md-h4 font-weight-bold headline text-uppercase">
                                About us
                              </span>
                              <div class="boxdivider"></div>
                            </v-col>
                            <v-col cols="12">
                              <v-responsive class="pt-1" :max-width="
                                  $vuetify.breakpoint.xsOnly ? 400 : '100%'
                                ">
                                <span class="text-h7 text-md-h7 font-weight-bold headline text-uppercase">
                                  BAJA CALIFORNIA QUALITY BEYOND THE BORDER
                                </span>
                                <span class="text-body-1 font-weight-light grey--text text--darken-2">
                                  <p>
                                    The <strong>Secretary of Tourism of the State of Baja California</strong>, Mexico,
                                    is proud to
                                    present the “Medical, Dental and Wellness Information” of our state.
                                    Our mission is to help patients like you to find the highest quality care at the
                                    lowest possible cost, with a wide range of quality accommodations, convenient
                                    transportation options, and of course, highly qualified and certified professionals
                                    who care about your well-being at all times, providing you with information that
                                    helps you make the best decision among the multiple options for medical
                                    destinations.
                                  </p>
                                  <p>
                                    In that sense, we invite you to enjoy all the great opportunities offered by this
                                    destination, which is distinguished by the beautiful Pacific beaches, the warm
                                    waters of the Sea of Cortez; its forests, mountains, desert and bays that contrast
                                    with the constant growth and development of this dynamic state, considered for its
                                    high-quality tourist services and high-level gastronomy, one of the main medical and
                                    dental tourist destinations for national and international visitors.
                                  </p>
                                </span>
                              </v-responsive>
                            </v-col>

                            <v-col cols="12">
                              <v-responsive class="pt-1" :max-width="$vuetify.breakpoint.xsOnly ? 400 : '100%'
                                ">
                                <span class="text-h7 text-md-h7 font-weight-bold headline text-uppercase">
                                  HEALTH AND WELL-BEING DESTINATION
                                </span>
                                <v-spacer></v-spacer>
                                <span class="text-h7 text-md-h7 font-weight-bold headline text-uppercase">
                                  ABOUT BAJA CALIFORNIA
                                </span>
                                <span class="text-body-1 font-weight-light grey--text text--darken-2">
                                  <p>
                                    The state of Baja California, Mexico, offers a wide variety of medical and wellness
                                    services, with cutting-edge technology and cutting-edge facilities, our medical and
                                    dental centers serve patients from all over the world, making our state a premier
                                    health destination. level, placing us as the main reference for health care in
                                    Mexico.
                                  </p>
                                  <p>
                                    Baja California currently receives more than 4.2 million visitors and companions a
                                    year specifically for health-related services. The most common procedures are:
                                  </p>
                                  <ul>
                                    <li>
                                      Dental services
                                    </li>
                                    <li>
                                      Aesthetic/plastic surgery
                                    </li>
                                    <li>
                                      Weight loss surgery
                                    </li>
                                    <li>
                                      Corrective vision surgery
                                    </li>
                                    <li>
                                      Spa and wellness services
                                    </li>
                                    <li>
                                      Innovative therapies
                                    </li>
                                  </ul>
                                </span>
                              </v-responsive>
                            </v-col>

                            <v-col cols="12">
                              <v-responsive class="pt-1" :max-width="$vuetify.breakpoint.xsOnly ? 400 : '100%'
                                ">
                                <span class="text-h7 text-md-h7 font-weight-bold headline text-uppercase">
                                  FIRST LEVEL HEALTH AND WELLNESS CARE
                                </span>
                                <span class="text-body-1 font-weight-light grey--text text--darken-2">
                                  <p>
                                    "First-level health and wellness care" is the standard that health professionals in
                                    Baja California have committed to focusing on providing to their patients, with
                                    these being their two priorities:
                                  </p>
                                  <ul>
                                    <li>
                                      Provide all patients with the highest quality healthcare available
                                    </li>
                                    <li>
                                      Reduce unnecessary costs in the health system, without compromising patient care
                                    </li>
                                  </ul>
                                  <p>
                                    Get to know Baja California's excellent health and well-being offer. Click on the
                                    following link
                                  </p>
                                </span>
                              </v-responsive>
                            </v-col>

                            <v-col cols="12">
                              <v-responsive class="pt-1" :max-width="$vuetify.breakpoint.xsOnly ? 400 : '100%'
                                ">
                                <span class="text-h7 text-md-h7 font-weight-bold headline text-uppercase">
                                  HISTORY OF MEDICAL TOURISM IN BAJA CALIFORNIA
                                </span>
                                <span class="text-body-1 font-weight-light grey--text text--darken-2">
                                  <p>
                                    Baja California, Mexico has become the #1 choice for American and Canadian patients
                                    who wish to receive health services outside of their own countries. The main reasons
                                    are:
                                  </p>
                                  <ol>
                                    <li>
                                      Increase in health and dental costs
                                    </li>
                                    <li>
                                      Increase in waiting time for procedures
                                    </li>
                                  </ol>
                                  <p>
                                    The proximity and high level of care in Baja California have attracted medical
                                    tourists since the early 1980s and 1990s. During this period, American and
                                    international patients began traveling to Baja California for their medical and
                                    dental needs. Today Baja California is one of the most reliable, accessible and
                                    efficient destinations for medical procedures, it is also consolidated as a dental
                                    destination for retired Americans and Canadians.
                                  </p>
                                </span>
                              </v-responsive>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>

                      <v-col cols="12">
                        <span class="text-h5 text-md-h4 font-weight-bold headline text-uppercase">
                          Partnerships
                        </span>
                        <div class="boxdivider"></div>
                      </v-col>

                      <v-col cols="12">
                        <v-slide-group show-arrows>
                          <v-slide-item>
                            <v-img src="@/assets/images/partnerships_1.png" :lazy-src="
                                require('@/assets/images/no-image.jpg')
                              " contain height="200px" width="200px" class="ma-4"></v-img>
                          </v-slide-item>

                          <v-slide-item>
                            <v-img src="@/assets/images/partnerships_2.png" :lazy-src="
                                require('@/assets/images/no-image.jpg')
                              " contain height="200px" width="200px" class="ma-4"></v-img>
                          </v-slide-item>
                          <v-slide-item>
                            <v-img src="@/assets/images/partnerships_3.png" :lazy-src="
                                require('@/assets/images/no-image.jpg')
                              " contain class="ma-4" height="200px" width="200px"></v-img>
                          </v-slide-item>
                          <v-slide-item>
                            <v-img src="@/assets/images/partnerships_4.png" :lazy-src="
                                require('@/assets/images/no-image.jpg')
                              " contain class="ma-4" width="200px" height="200px"></v-img>
                          </v-slide-item>
                        </v-slide-group>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AboutComponent",
  data: () => ({
    items: [
      {
        text: "Home",
        disabled: false,
        href: "/homepage",
      },
      {
        text: "About us",
        disabled: true,
        href: "/about",
      },
    ],
  }),
};
</script>

<style scoped>
/*.v-image__image{
    background-size:100% 100%;
}*/
.bottom-gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(163, 153, 248, 0.295) 0%,
    rgba(101, 99, 248, 0.699) 100%
  );
}

.blend-opacity {
  opacity: 0.1;
  transition: opacity 0.4s ease-in-out;
  background-color: black;
  background-blend-mode: normal;
}

.boxdivider {
  background-color: transparent;
  width: 100px;
  height: 20px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom-width: 3px;
  border-bottom-color: blueviolet;
  border-bottom-style: solid;
  display: block;
}
</style>
